import React, {useContext, useEffect, useState} from "react";
import {Col, Row, Tabs, Switch, PageHeader} from "antd";
import {AppProps} from "../../common/AppEnums";
import InspectionsBotTab from "./components/InspectionsBotTab";
import UsersManagementTab from "./components/UsersManagementTab";
import GroupsManagementTab from "./components/GroupsManagementTab";
import MenuAccessManagementTab from "./components/MenuAccessManagementTab";
import TenantManagementTab from "./components/TenantManagementTab";
import TransactionTemplatesTab from "./components/txtemplates/TransactionTemplatesTab";
import WorkConditionsTab from "./components/WorkConditionsTab";
import TransactionTemplatesStatisticsTab from "./components/txtemplates/TransactionTemplatesStatisticsTab";
import ParkManagementTab from "./components/ParkManagementTab";
import PrintFormsTab from "./components/PrintFormsTab";
import UserProfileContext from "../../../services/UserProfileContext";
import FinesSettingsTab from "./components/FinesSettingsTab";
import PhotocontrolBotTab from "./components/PhotocontrolBotTab";
import MenuButton from "../../buttons/MenuButton";
import TgNotificationsSettingsTab from "./components/TgNotificationsSettingsTab";
import DictionariesTab from "./components/DictionariesTab";
import SpectrumIntegrationsTab from "./components/SpectrumIntegrationsTab";
import TollRoadDebtsSettingsTab from "./components/TollRoadDebtsSettingsTab";
import SupplyHoursTab from "./components/SupplyHoursTab";

const { TabPane } = Tabs;

export default function SettingsPage({onPropsChanged, onPictureChanges}) {
  const userProfile = useContext(UserProfileContext);

  let [enableMenuEdit, setEnableMenuEdit] = useState(false);
  let [update, setUpdate] = useState(0);
  let [state, setState] = useState({activeTabKey: "1", update: 0});

  const updateEnableMenuEdit = checked => {
    localStorage.setItem(AppProps.EnableMenuEdit, checked);

    setEnableMenuEdit(checked);

    onPropsChanged({enableMenuEdit: checked})
  };

  useEffect(() => {
    if(localStorage.getItem(AppProps.EnableMenuEdit) !== null) {
      setEnableMenuEdit(localStorage.getItem(AppProps.EnableMenuEdit) === "true");
    }
  }, []);

  const restricted = (component) => {
    if(!userProfile || !userProfile.is_superuser) {
      return null;
    }
    return component;
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Настройки"
      />
      <div className="scrolling-wrapper-flexbox" style={{marginBottom: 16}}>
        <MenuButton className="card" value = "Основные" statusBtn={state.activeTabKey === "1"} onClick={() => setState({...state, activeTabKey: "1", update: state.update + 1})}/>
        {
          restricted(
            <MenuButton className="card" value = "Организации" statusBtn={state.activeTabKey === "2"} onClick={() => setState({...state, activeTabKey: "2", update: state.update + 1})}/>
        )}
        <MenuButton className="card" value = "Пользователи" statusBtn={state.activeTabKey === "3"} onClick={() => setState({...state, activeTabKey: "3", update: state.update + 1})}/>
        <MenuButton className="card" value = "Роли" statusBtn={state.activeTabKey === "4"} onClick={() => setState({...state, activeTabKey: "4", update: state.update + 1})}/>
        <MenuButton className="card" value = "Доступ к меню" statusBtn={state.activeTabKey === "5"} onClick={() => setState({...state, activeTabKey: "5", update: state.update + 1})}/>
        <MenuButton className="card" value = "Диспетчерские" statusBtn={state.activeTabKey === "6"} onClick={() => setState({...state, activeTabKey: "6", update: state.update + 1})}/>
        <MenuButton className="card" value = "Списания штрафов с водителя" statusBtn={state.activeTabKey === "7"} onClick={() => setState({...state, activeTabKey: "7", update: state.update + 1})}/>
        <MenuButton className="card" value = "Списания задолженностей ЦКАД" statusBtn={state.activeTabKey === "roadDebtsSettings"} onClick={() => setState({...state, activeTabKey: "roadDebtsSettings", update: state.update + 1})}/>
        <MenuButton className="card" value = "Бот-механик" statusBtn={state.activeTabKey === "8"} onClick={() => setState({...state, activeTabKey: "8", update: state.update + 1})}/>
        <MenuButton className="card" value = "Бот-водитель" statusBtn={state.activeTabKey === "9"} onClick={() => setState({...state, activeTabKey: "9", update: state.update + 1})}/>
        <MenuButton className="card" value = "Условия работы" statusBtn={state.activeTabKey === "10"} onClick={() => setState({...state, activeTabKey: "10", update: state.update + 1})}/>
        <MenuButton className="card" value = "Печатные формы" statusBtn={state.activeTabKey === "11"} onClick={() => setState({...state, activeTabKey: "11", update: state.update + 1})}/>
        <MenuButton className="card" value = "Уведомления" statusBtn={state.activeTabKey === "12"} onClick={() => setState({...state, activeTabKey: "12", update: state.update + 1})}/>
        {
          restricted(
            <MenuButton className="card" value = "Учет времени" statusBtn={state.activeTabKey === "supplyHours"} onClick={() => setState({...state, activeTabKey: "supplyHours", update: state.update + 1})}/>
          )
        }
        {
          restricted(
            <MenuButton className="card" value = "Отчеты Спектрум" statusBtn={state.activeTabKey === "spectrumIntegrations"} onClick={() => setState({...state, activeTabKey: "spectrumIntegrations", update: state.update + 1})}/>
          )
        }
        <MenuButton className="card" value = "Справочники" statusBtn={state.activeTabKey === "13"} onClick={() => setState({...state, activeTabKey: "13", update: state.update + 1})}/>
      </div>
      {state.activeTabKey === "1" && (
        <>
          <Row style={{margin: '8px 0'}}>
            <Col span={5}>
              <h4>Редактирование меню</h4>
            </Col>
            <Col span={4}>
              <Switch checked={enableMenuEdit}
                      checkedChildren={"Включить"}
                      unCheckedChildren={"Отключить"}
                      onChange={(checked, event) => { updateEnableMenuEdit(checked); }}/>
            </Col>
          </Row>
        {/*  <Row style={{margin: '8px 0'}}>*/}
        {/*  <Col span={5}>*/}
        {/*    <h4>Загрузить логотип</h4>*/}
        {/*  </Col>*/}
        {/*  <Col span={4}>*/}
        {/*    <Upload*/}
        {/*      listType="picture-card"*/}
        {/*      name={"file"}*/}
        {/*      accept={"image/*"}*/}
        {/*      action={apiClient.actionUrl(`settings/import`)}*/}
        {/*      onChange={onPictureChanges}*/}
        {/*    >*/}
        {/*      <Button type="primary" icon={<UploadOutlined />}>Загрузить</Button>*/}
        {/*    </Upload>*/}
        {/*  </Col>*/}
        {/*</Row>*/}
      </>
      )}
      {state.activeTabKey === "2" && (
        <TenantManagementTab hash={update} onUpdated={() => { setUpdate(u => u + 1) }}/>
      )}
      {state.activeTabKey === "3" && (
        <UsersManagementTab active={state.activeTabKey === "3"}/>
      )}
      {state.activeTabKey === "4" && (
        <GroupsManagementTab
          hash={update}
          active={state.activeTabKey === "4"}
          onUpdated={() => { setUpdate(u => u + 1) }}
        />
      )}
      {state.activeTabKey === "5" && (
        <MenuAccessManagementTab hash={update}/>
      )}
      {state.activeTabKey === "6" && (
        <ParkManagementTab/>
      )}
      {state.activeTabKey === "7" && (
        <FinesSettingsTab active={state.activeTabKey === "7"}/>
      )}
      {state.activeTabKey === "roadDebtsSettings" && (
        <TollRoadDebtsSettingsTab active={state.activeTabKey === "roadDebtsSettings"}/>
      )}
      {state.activeTabKey === "8" && (
        <InspectionsBotTab/>
      )}
      {state.activeTabKey === "9" && (
        <PhotocontrolBotTab active={state.activeTabKey === "9"}/>
      )}
      {state.activeTabKey === "10" && (
        <Tabs tabPosition={"left"}>
          <TabPane tab="Шаблоны списаний" key="8-2">
            <TransactionTemplatesTab/>
          </TabPane>
          <TabPane tab="Условия работы" key="8-1">
            <WorkConditionsTab/>
          </TabPane>
          <TabPane tab="Статистика" key="8-3">
            <TransactionTemplatesStatisticsTab/>
          </TabPane>
        </Tabs>
      )}
      {state.activeTabKey === "11" && (
        <PrintFormsTab active={state.activeTabKey === "11"}/>
      )}
      {state.activeTabKey === "12" && (
        <TgNotificationsSettingsTab active={state.activeTabKey === "12"}/>
      )}
      {state.activeTabKey === "spectrumIntegrations" && (
        <SpectrumIntegrationsTab active={state.activeTabKey === "spectrumIntegrations"}/>
      )}
      {state.activeTabKey === "13" && (
        <DictionariesTab active={state.activeTabKey === "13"}/>
      )}
      {state.activeTabKey === "supplyHours" && (
        <SupplyHoursTab active={state.activeTabKey === "supplyHours"}/>
      )}
    </>
  );
}