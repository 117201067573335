import React, {useEffect, useState} from "react";
import {
  AutoComplete,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  notification, Rate,
  Row,
  Select,
  Space
} from "antd";
import {Redirect, useParams} from "react-router-dom";
import {apiClient} from "../../../services/ApiClient";
import {CarReturnReasons} from "../../common/AppEnums";
import './CarReturnPage.css'

const { Option } = Select;
const { TextArea } = Input;

export default function CarReturnPage(props) {

  let { tenantCode = '49278d83-2044-4f17-bca9-483aed1727ac' } = useParams();

  let [loading, setLoading] = useState(false);
  let [searching, setSearching] = useState(false);
  let [formSubmitted, setFormSubmitted] = useState(false);
  let [requestId, setRequestId] = useState(apiClient.uuidv4());
  let [suggestions, setSuggestions] = useState([]);
  let [carInfo, setCarInfo] = useState('');
  let [record, setRecord] = useState(null);
  let [tenantCodeCheck, setTenantCodeCheck] = useState("checking");

  useEffect(() => {
    apiClient.checkTenant({code: tenantCode})
      .then(res => {
        if(res.success) {
          setTenantCodeCheck("valid");
        } else {
          setTenantCodeCheck("failed");
        }
      })
      .catch(err => {
        setTenantCodeCheck("failed");
      });
  }, [tenantCode]);

  const filterString = str => {
    return str.replace(/[^0-9a-zA-Zа-яА-Я\s]/g, "");
  };

  const filterPhone = str => {
    str = str.replace(/[^0-9]/g, "");
    return str.substring(0, 10);
  };

  const prepareData = (formData) => {
    return {
      "uid": record?.id,
      "fio": formData.fio,
      "carNumber": record?.car_number,
      "returnReason": formData.returnReason,
      "returnDate": formData.returnDate?.format('YYYY-MM-DD'),
      "requestId": requestId,
      "tid": tenantCode,
      "carInfo": record?.car_model,
      "positiveFeedback": formData.positiveFeedback,
      "negativeFeedback": formData.negativeFeedback,
      "recomendationRating": formData.recomendationRating,
    }
  };

  const [form] = Form.useForm();

  const onFinish = (formData) => {
    if(!carInfo) {
      notification.error({message: "Выберите авто по номеру из списка"});
      return;
    }

    setLoading(true);
    apiClient
      .addCarReturnRequest(prepareData(formData))
      .then(() => {
        // clear form
        form.setFieldsValue({ fio: '', phone: '' });
        // update request id
        setRequestId(apiClient.uuidv4());
        //
        setFormSubmitted(true);
      })
      .catch(() => {
        // notify user
        notification.error({message: "Ошибка. Повторите операцию"});
      })
      .finally(() => {setLoading(false)});
  };

  const search = (val) => {
    val = filterPhone(val);
    if(!val) {
      return;
    }
    setSearching(true);
    apiClient.searchPaymentDriver(val, tenantCode)
      .then(res => {
        setSuggestions(res.records.map(e => {
          return {
            key: e.id,
            value: e.phone.substring(2),
            label: e.phone.substring(2),
            record: e
          }
        }));
      })
      .catch(err => {
        message.error("Не удалось выполнить поиск")
      })
      .finally(() => { setSearching(false); });
  };

  if(formSubmitted) {
    return <Redirect to={`/vozvrat-complete/${tenantCode}`}/>;
  }

  if(tenantCodeCheck === "checking") {
    return (
      <div style={{margin: '10px auto', width: 300, fontSize: '2em'}}>Загрузка...</div>
    )
  }

  if(tenantCodeCheck === "failed") {
    return (
      <div style={{margin: '10px auto', width: 400, fontSize: '2em'}}>Организация не найдена :(</div>
    )
  }

  return (
    <React.Fragment>
      <Row justify="space-around" align="middle">
        <Col xs={1} md={2}> </Col>
        <Col xs={22} md={20}>
          <div>
            <Form
              form={form}
              className="user-form"
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onValuesChange={(changedValues, allValues) => {
                console.log(prepareData(allValues));
              }}
            >
              <div style={{textAlign: 'center'}}>
                <h1>Если вы планируете сдать авто, заполните анкету</h1>
              </div>

              <p className="subtitle">
                О сдаче транспортного средства необходимо предупредить за 3 (три) рабочих дня, заполнив данную анкету. В данном случае Ваш накопленный залоговый платеж подлежит возврату, если не имеется других нарушений. Если у Вас произошли непредвиденные обстоятельства (Болезнь, Смерть близких родственников и т.д.), Вы можете сдать транспортное средство раньше, при этом для рассмотрения возврата страховых платежей потребуются подтверждающие документы.
              </p>

              <div className="form-fields">

                <Form.Item
                  label="Номер телефона"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: 'Введите номер телефона, начиная с 9 (10 цифр)',
                    },
                  ]}
                >
                  <Space direction={"horizontal"}>
                    <span>+7 </span>
                    <AutoComplete
                      allowClear={true}
                      options={suggestions}
                      onSelect={(val, opt) => {
                        setRecord(opt.record);
                        form.setFieldsValue({
                          phone: filterString(opt.record.phone.substring(2)),
                          fio: opt.record.name
                        });
                        setCarInfo(opt.record.car_model);
                        setSuggestions([]);
                      }}
                      onSearch={search}
                      onChange={(val) => {
                        if(val === undefined) {
                          setRecord(null);
                          setCarInfo("");
                          setSuggestions([]);
                          form.setFieldsValue({ phone: '', fio: '' });
                        } else {
                          if(val.indexOf("+") === 0 || val.indexOf("7") === 0 || val.indexOf("8") === 0) {
                            val = val.substring(1);
                          }
                          form.setFieldsValue({ phone: val });
                        }
                      }}
                      style={{ width: 300 }}
                      placeholder={"Телефон, 10 цифр, начиная с 9"}
                    />
                  </Space>
                </Form.Item>
                {
                  carInfo
                    ? <div style={{textAlign: 'right'}}>
                      <b>Марка, модель:</b> {carInfo}
                    </div>
                    : null
                }

                <Form.Item
                  label="ФИО"
                  name="fio"
                  rules={[
                    {
                      required: true,
                      message: 'Укажите ФИО',
                    },
                  ]}
                >
                  <Input placeholder="Введите ваше ФИО"/>
                </Form.Item>

                <Form.Item
                  label="Дата возврата автомобиля"
                  name="returnDate"
                  rules={[
                    {
                      required: true,
                      message: 'Укажите дату возврата автомобиля',
                    },
                  ]}
                >
                  <DatePicker format="DD.MM.YYYY" placeholder="Дата возврата автомобиля" />

                </Form.Item>

                <Form.Item
                  label={
                    <div>
                      <label>Почему вы решили сдать авто?</label>
                      <p style={{fontSize: '0.9em'}}>Нам важно знать Ваше мнение, мы работаем над качеством нашей компании</p>
                    </div>
                  }
                  name="returnReason"
                  rules={[
                    {
                      required: true,
                      message: 'Укажите причину возврата',
                    },
                  ]}
                >
                  <Select bordered placeholder="Выберите вариант из списка">
                    {CarReturnReasons.map(s => <Option key={s.value} value={s.value}>{s.text}</Option>)}
                  </Select>

                </Form.Item>

                <Form.Item
                  label={
                    <div>
                      <label>Плюсы сотрудничества с нами</label>
                    </div>
                  }
                  name="positiveFeedback"
                  rules={[]}
                >
                  <TextArea rows={3}/>
                </Form.Item>

                <Form.Item
                  label={
                    <div>
                      <label>Минусы сотрудничества с нами</label>
                    </div>
                  }
                  name="negativeFeedback"
                  rules={[]}
                >
                  <TextArea rows={3}/>
                </Form.Item>

                <Form.Item
                  label="Оцените по шкале от 0 до 10 вероятность того, что Вы порекомендуете компанию друзьям и знакомым"
                  name="recomendationRating"
                  style={{textAlign: "center"}}
                  rules={[
                    {
                      required: true,
                      message: 'Поле обязательно для заполнения',
                    },
                  ]}
                >
                  <Rate count={10} style={{fontSize: '2.5em'}} character={({ index }) => <span style={{margin: '0 5px'}}>{index + 1}</span>}/>
                </Form.Item>

                <Form.Item
                  label="Согласие с политикой конфиденциальности"
                  name="agreement"
                  valuePropName="checked"
                  rules={[
                    {
                      required: true,
                      message: 'Необходимо принять политику конфиденцильности',
                    },
                  ]}
                >
                  <Checkbox>Нажимая кнопку «Отправить», я даю свое согласие на обработку персональных данных, в соответствии с Федеральным законом №152-ФЗ «О персональных данных»</Checkbox>
                </Form.Item>

              </div>

              <div style={{textAlign: "center"}}>
                <Form.Item>
                  <Button type="primary"
                          htmlType="submit"
                          className="form-button"
                          loading={loading}>
                    Отправить
                  </Button>
                </Form.Item>
              </div>

            </Form>
          </div>
        </Col>
        <Col xs={1} md={2}> </Col>
      </Row>
    </React.Fragment>
  );
}