import React, {useEffect, useRef, useState} from "react"
import {
  Button,
  Col,
  Input,
  Row,
  notification,
  AutoComplete,
  message,
  Alert,
  Select,
  Result,
  Spin
} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {apiClient} from "../../services/ApiClient";

import "./PaymentPage.css"
import numeral from "numeral"

const { Option } = Select;

const PROVIDER_QIWI = "qiwi";
const PROVIDER_PAYONLINE = "payonline";
const PROVIDER_SBP = "sbp";

const SBP_ACCEPTED = "Accepted";
const SBP_REJECTED = "Rejected";
const SBP_EXPIRED = "Expired";

export default function PaymentPage({tenantCode, paymentProviders, infoContent}) {

  const formRef = useRef(null);

  let [loading, setLoading] = useState(false);
  let [searching, setSearching] = useState(false);
  let [record, setRecord] = useState(null);
  let [uid, setUid] = useState('');
  let [total, setTotal] = useState('');
  let [netTotal, setNetTotal] = useState('0.00');
  let [comission, setComission] = useState('0.00');
  let [regNumber, setRegNumber] = useState('');
  let [suggestions, setSuggestions] = useState([]);
  let [successAlert, setSuccessAlert] = useState(false);
  let [failAlert, setFailAlert] = useState(false);
  let [description, setDescription] = useState('');
  let [oid, setOid] = useState('');
  let [qrcId, setQrcId] = useState('');
  let [qrcStatus, setQrcStatus] = useState('');
  let [paymentProvider, setPaymentProvider] = useState(PROVIDER_SBP);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if(String(urlParams.get('success')).toLowerCase() === "true") {
      setSuccessAlert(true);
    } else if(String(urlParams.get('success')).toLowerCase() === "false") {
      setFailAlert(true);
    }
  }, []);

  const filterString = str => {
    return str.replace(/[^0-9a-zA-Zа-яА-Я\s]/g, "");
  };

  const filterPhone = str => {
    str = str.replace(/[^0-9]/g, "");
    return str.substring(0, 10);
  };

  const search = (val) => {
    val = filterPhone(val);
    if(!val) {
      return;
    }
    setSearching(true);
    apiClient.searchPaymentDriver(val, tenantCode)
      .then(res => {
        setSuggestions(res.records.map(e => {
          return {
            key: e.id,
            value: e.phone,
            label: e.phone,
            record: e
          }
        }));
      })
      .catch(err => {
        message.error("Не удалось выполнить поиск")
      })
      .finally(() => { setSearching(false); });
  };

  const startPolling = (id) => {
    apiClient.checkSbpTransaction(id)
      .then(res => {
        setQrcStatus(res.status);
        if(res.status !== SBP_ACCEPTED && res.status !== SBP_REJECTED && res.status !== SBP_EXPIRED) {
          setTimeout(() => { startPolling(id) }, 1000);
        }
      })
      .catch(err => {
        console.error(err);
        setTimeout(() => { startPolling(id) }, 1000);
      })
  };

  const onPayClick = () => {
    if(paymentProviders.filter(el => el.id === paymentProvider).length < 1) {
      notification.error({message: "Платежи для указанного провайдера не принимаются"});
      return;
    }
    if(!record) {
      notification.error({message: "Номер не зарегистрирован"});
      return;
    }
    if(!uid) {
      notification.error({message: "Укажите ID"});
      return;
    }
    if(!netTotal || numeral(netTotal).value() < 1) {
      notification.error({message: "Укажите сумму аренды"});
      return;
    }
    if(!regNumber) {
      notification.error({message: "Укажите регистрационный номер ТС"});
      return;
    }

    let wnd = null;
    if(paymentProvider === PROVIDER_PAYONLINE) {
      wnd = window.open();
    }

    setLoading(true);
    if(paymentProvider === PROVIDER_SBP) {
      apiClient.prepareSbpTransaction({
        t: tenantCode,
        u: uid,
        a: total,
        n: netTotal,
        c: comission,
        r: regNumber,
        p: paymentProvider
      })
        .then(res => {
          setDescription(res.description);
          setQrcId(res.qrcid);

          startPolling(res.qrcid);

          window.open(res.payload, "_blank");
        })
        .catch(err => {
          notification.error({message: err})
        })
        .finally(() => setLoading(false));

    } else {
      apiClient.prepareTransaction({
        t: tenantCode,
        u: uid,
        a: total,
        n: netTotal,
        c: comission,
        r: regNumber,
        p: paymentProvider
      })
        .then(res => {
          setDescription(res.description);
          setOid(res.oid);

          if (paymentProvider === PROVIDER_PAYONLINE) {
            let descr = encodeURIComponent(res.description);
            let returnUrl = encodeURI("https://www.tkpolet.ru/payrent?success=true");
            let failUrl = encodeURI("https://www.tkpolet.ru/payrent?success=false");
            let url = `https://secure.payonlinesystem.com/ru/payment?MerchantId=${res.mid}&OrderId=${res.oid}&Amount=${res.amount}&Currency=RUB&OrderDescription=${descr}&SecurityKey=${res.pk}&ReturnUrl=${returnUrl}&FailUrl=${failUrl}`;
            if (wnd) {
              wnd.location.href = url;
            }
          } else {
            formRef.current.submit();
          }

          setRecord(null);
          setUid("");
          setTotal("");
          setRegNumber("");
          setNetTotal("0.00");
          setComission("0.00");
          setDescription('');
          setOid('');
        })
        .catch(err => {
          notification.error({message: err})
        })
        .finally(() => setLoading(false));

    }
  };

  const content = () => {
    return(
      <div className="content">
        <h2>Оплата аренды транспортного средства</h2>
        {
          successAlert
            ? <p><Alert message="Платеж выполнен успешно!" type="success" /></p>
            : null
        }
        {
          failAlert
            ? <p><Alert message="Ошибка при выполнении платежа. Обратитесь к оператору!" type="error" /></p>
            : null
        }
        { infoContent }
        <div className="attributes">
          <div className="attribute">
            <label>Номер телефона {searching ? <LoadingOutlined /> : null}</label>
            <div>
              <div style={{display:'inline-block', fontSize: '1.3em', margin: '5px'}}>+7</div>
              <AutoComplete
                size="large"
                pattern="\d*"
                style={{ width: '85%', float: 'right' }}
                options={suggestions}
                onSelect={(val, opt) => {
                  setRecord(opt.record);
                  setUid(filterString(opt.record.phone.substring(2)));
                  setRegNumber(filterString(opt.record.car_number));
                  setSuggestions([]);
                }}
                onSearch={search}
                onChange={(val) => {
                  if(val === undefined) {
                    setRecord(null);
                    setUid('');
                    setRegNumber('');
                  } else {
                    if(val.indexOf("+") === 0) {
                      val = val.substring(1);
                    }
                    if(val.indexOf("7") === 0) {
                      val = val.substring(1);
                    }
                    setUid(filterPhone(val));
                  }
                }}
                value={uid}
                allowClear={true}
                placeholder={"Телефон"}
              />
            </div>
          </div>
          <div className="attribute">
            <label>Гос. номер автомобиля</label>
            <Input size="large" readOnly={true} placeholder="Номер транспортного средства" value={regNumber} onChange={(e) => {
              setRegNumber(filterString(e.target.value));
            }}/>
          </div>
          <div className="attribute">
            <label>Сумма аренды</label>
            <Input size="large" placeholder="Сумма" type="number" value={total} onChange={(e) => {
              setTotal(e.target.value);
              if(!e.target.value) {
                setNetTotal(numeral(0).format('0.00'));
              } else {
                const provider = paymentProviders.find(el => el.id === paymentProvider);

                let totalValue = numeral(e.target.value);
                let comission = numeral(e.target.value).multiply(provider.commission);

                if(comission.value() < provider.minCommission) {
                  comission = numeral(provider.minCommission);
                }

                if(totalValue.value() < comission.value()) {
                  totalValue = comission;
                }

                setNetTotal(numeral(totalValue.difference(comission.value())).format('0.00'));
                setComission(comission.format('0.00'));
              }
            }}/>
            <div className="total">К зачислению: <b>{netTotal}</b> р., комиссия: <b>{comission}</b> р.</div>
          </div>
          <div className="attribute">
            <label>Платежная система</label>
            <Select bordered allowClear
                    placeholder="Платежная система"
                    onChange={(e) => {
                      if(total) {
                        const provider = paymentProviders.find(el => el.id === e);

                        let totalValue = numeral(total);
                        let comission = numeral(total).multiply(provider.commission);

                        if(comission.value() < provider.minCommission) {
                          comission = numeral(provider.minCommission);
                        }

                        if(totalValue.value() < comission.value()) {
                          totalValue = comission;
                        }

                        setNetTotal(numeral(totalValue.difference(comission.value())).format('0.00'));
                        setComission(comission.format('0.00'));
                      }
                      setPaymentProvider(e);
                    }}
                    value={paymentProvider}
                    style={{width: '100%'}}
                    size="large" >
              {
                paymentProviders.map(el => <Option key={el.id} value={el.id}>{el.title}</Option>)
              }
            </Select>
          </div>
        </div>
        <br/>
        <Button size="large"
                loading={loading}
                onClick={() => onPayClick()}
                style={{width: 200, height: 60, color: "#fff", backgroundColor: "#000000", fontWeight: "bold"}}>
          Оплатить аренду
        </Button>
        <div style={{marginTop: 20, textAlign: 'left'}}>
          <h3>Правила оплаты</h3>
          <p>
            «Уважаемые Клиенты, информируем Вас о том, что при запросе возврата денежных средств при отказе от покупки, возврат производится исключительно на ту же банковскую карту, с которой была произведена оплата».
          </p>
          <p>
            К оплате принимаются платежные карты: VISA Inc, MasterCard WorldWide, МИР
            Для оплаты товара банковской картой при оформлении заказа в интернет-магазине выберите способ оплаты: банковской картой.
            При оплате заказа банковской картой, обработка платежа происходит на авторизационной странице банка, где Вам необходимо ввести данные Вашей банковской карты:
            <ol>
              <li>Тип карты</li>
              <li>Номер карты</li>
              <li>Срок действия карты (указан на лицевой стороне карты)</li>
              <li>Имя держателя карты (латинскими буквами, точно также как указано на карте)</li>
              <li>CVC2/CVV2 код</li>
            </ol>
          </p>
          <p>
            Если Ваша карта подключена к услуге 3D-Secure, Вы будете автоматически переадресованы на страницу банка, выпустившего карту, для прохождения процедуры аутентификации. Информацию о правилах и методах дополнительной идентификации уточняйте в Банке, выдавшем Вам банковскую карту.
          </p>
          <p>
            Безопасность обработки интернет-платежей через платежный шлюз банка гарантирована международным сертификатом безопасности PCI DSS. Передача информации происходит с применением технологии шифрования TLS. Эта информация недоступна посторонним лицам.
          </p>
          <p>
            Советы и рекомендации по необходимым мерам безопасности проведения платежей с использованием банковской карты:
            <ol>
              <li>
                берегите свои пластиковые карты так же, как бережете наличные деньги. Не забывайте их в машине, ресторане, магазине и т.д.
              </li>
              <li>
                никогда не передавайте полный номер своей кредитной карты по телефону каким-либо лицам или компаниям
              </li>
              <li>
                всегда имейте под рукой номер телефона для экстренной связи с банком, выпустившим вашу карту, и в случае ее утраты немедленно свяжитесь с банком
              </li>
              <li>
                вводите реквизиты карты только при совершении покупки. Никогда не указывайте их по каким-то другим причинам.
              </li>
            </ol>
          </p>
          <div style={{textAlign: "center"}}>
            <img style={{width: 300, margin: 5}} src="/assets/pravila-oplaty1.jpg" alt=""/>
            <img style={{width: 300, margin: 5}} src="/assets/pravila-oplaty2.jpg" alt=""/>
          </div>
        </div>
        <form ref={formRef} method="post" action="https://tkpolet.server.paykeeper.ru/create/">
          <input type="hidden" name="sum" value={total}/>
          <input type="hidden" name="orderid" value={oid}/>
          <input type="hidden" name="service_name" value={description}/>
          <input type="hidden" name="client_phone" value={uid}/>
          <input type="hidden" name="user_result_callback" value="https://www.tkpolet.ru/payrent"/>
        </form>
      </div>
    );
  };

  const showQrcStatus = (status) => {
    if(status === SBP_ACCEPTED) {
      return (
        <Result
          status="success"
          title="Оплата успешно совершена"
          subTitle="Платеж отразится в личном кабинете в течение 3-5 минут"
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => {
                setRecord(null);
                setUid("");
                setTotal("");
                setRegNumber("");
                setNetTotal("0.00");
                setComission("0.00");
                setDescription('');
                setQrcId('');
                setQrcStatus('');
              }}>
              Закрыть
            </Button>,
          ]}
        />
      )
    } else {
      return (
        <Result
          status="error"
          title="Ошибка, не удалось совершить платеж"
          subTitle="Попробуйте еще раз или обратитесь к оператору"
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => {
                setRecord(null);
                setUid("");
                setTotal("");
                setRegNumber("");
                setNetTotal("0.00");
                setComission("0.00");
                setDescription('');
                setQrcId('');
                setQrcStatus('');
              }}>
              Еще раз
            </Button>
          ]}
        />
      )
    }
  };

  const showQrcChecking = () => {
    return (
      <Result
        status="warning"
        title="Проверка статуса операции, не закрывайте страницу..."
        extra={
          <Spin size="large" />
        }
      />
    )
  };

  return (
    <>
      <Row>
        <Col xs={2} sm={2} md={4} lg={6} xl={6}>
          &nbsp;
        </Col>
        <Col xs={20} sm={20} md={16} lg={12} xl={12}>
          {
            qrcId ?
              ([SBP_ACCEPTED, SBP_REJECTED, SBP_EXPIRED].some(el => el === qrcStatus) ? showQrcStatus(qrcStatus) : showQrcChecking())
              : content()
          }
        </Col>
        <Col xs={2} sm={2} md={4} lg={6} xl={6}>
          &nbsp;
        </Col>
      </Row>
    </>
  )
}