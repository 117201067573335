/**
 *
 *
 * @type {({text: string, value: string}|{text: string, value: string}|{text: string, value: string}|{text: string, value: string}|{text: string, value: string})[]}
 */
const WorkStatuses = [
  {value: "offline", text: "Оффлайн", shortText: "Оффлайн", title: "Оффлайн"},
  {value: "busy", text: "Занят", shortText: "Занят", title: "Занят"},
  {value: "free", text: "Свободен", shortText: "Свободен", title: "Свободен"},
  {value: "in_order_free", text: "На заказе (цепочка включена)", shortText: "На заказе", title: "На заказе, свободен (цепочка включена)"},
  {value: "in_order_busy", text: "На заказе (цепочка выключена)", shortText: "На заказе", title: "На заказе, свободен (цепочка выключена)"},
];

/**
 *
 *
 * @type {({title: string, value: string}|{text: string, value: string}|{text: string, value: string}|{text: string, value: string}|{text: string, value: string})[]}
 */
const LegalStatuses = [
  {value: "driver", title: "Водители"},
  {value: "selfemployed", title: "Парковые самозанятые"},
];

/**
 *
 *
 * @type {({text: string, value: number}|{text: string, value: number})[]}
 */
const ActivityStatuses = [
  {value: 1, text: "Активен"},
  {value: 2, text: "Заблокирован"},
];

/**
 * Причины возврата авто
 *
 * @type {({text: string, value: number}|{text: string, value: number}|{text: string, value: number}|{text: string, value: number}|{text: string, value: number})[]}
 */
const CarReturnReasons = [
  {value: 1, text: "Планирую отдохнуть"},
  {value: 2, text: "Хочу поменять класс авто"},
  {value: 3, text: "Хочу поменять таксопарк"},
  {value: 4, text: "Не устраивает стоимость аренды"},
  {value: 5, text: "Решил поменять вид деятельности"},
  {value: 6, text: "Конфликт с сотрудниками парка"},
  {value: 7, text: "Не устраивают условия сотрудничества"},
  {value: 8, text: "Непредвиденные обстоятельства (форс-мажор)"},
];

/**
 * Виды перевозки
 *
 * @type {({text: string, value: string})[]}
 */
const TransferTypes = [
  {value: "regular", text: "Регулярная"},
  {value: "order", text: "По заказу"},
  {value: "taxi", text: "Легковым такси"},
];

/**
 * Виды транспортных сообщений
 *
 * @type {({text: string, value: string})[]}
 */
const TransferConnections = [
  {value: "city", text: "Городской"},
  {value: "subcity", text: "Пригородный"},
  {value: "intercity", text: "Междугородний"},
  {value: "intercountry", text: "Международный"},
];

/**
 * Периодичность операций
 *
 * @type {({text: string, value: string})[]}
 */
const Periodicity = [
  {value: "daily", text: "Ежедневно"},
  {value: "weekly", text: "Еженедельно"},
  {value: "monthly", text: "Ежемесячно"},
];

const AppProps = {
  EnableMenuEdit: "SettingsPage.enableMenuEdit",
  IsCompletedTransactionTemplatesVisible: "AssignedTransactionTemplatesTab.isCompleteVisible",
};

const DriverPhotoControlTypes = {
  "front": "Лицевая часть ТС",
  "right": "Правая часть ТС",
  "rightSide": "Правая часть ТС",
  "back": "Задняя часть ТС",
  "backSide": "Задняя часть ТС",
  "left": "Левая часть ТС",
  "leftSide": "Левая часть ТС",
  "seatCovers": "Фото чехлов",
  "frontSeats": "Фото передних сидений",
  "frontOfTheVehicleInterior": "Фото передних сидений",
  "rearSeats": "Фото задних сидений ТС",
  "rearOfTheVehicleInterior": "Фото задних сидений ТС",
  "frontTorpedo": "Фото передней части торпедо",
  "dashboard": "Фото приборной панели",
  "enabledDashboard": "Фото включенной приборной панели",
  "oilLevel": "Фото щупа с уровнем масла",
  "antifreeze": "Фото бочка антифриза",
  "brakeFluid": "Фото бочка с тормозной жидкостью",
  "trunk": "Фото открытого багажника  с набором автомобилиста",
  "vin": "Vin - код кузова",
  "selfie": "Селфи себя на фоне ТС",
  "vehicleDamage": "Повреждения",
  "taxiChecker": "Шашка такси",
  "frontLights": "Передние световые приборы",
  "rearLights": "Задние световые приборы",
  "spareWheel": "Запасное колесо",
  "phraserCard": "Карточка фрактовщика",
  "motoristSet": "Набор автомобилиста",
  "documents": "Документы",
  "wheelBraid": "Оплетка на руле",
  "dashboardErrors": "Ошибки на панели приборов",
  "stopSignal": "Стоп-сигнал",
  "applicationStatus": "Статус в приложении",
};

const PhotoControlStatuses = {
  "new": "Новая",
  "check": "Проверка",
  "wait": "Ожидание обновления",
  "updated": "Обновлено",
  "complete": "Принято",
};

const DriverTransactionCategories = [
  {value: "bank_payment", text: "Выплата на расчетный счёт партнера"},
  {value: "bonus", text: "Бонус"},
  {value: "card", text: "Оплата по карте"},
  {value: "cash_collected", text: "Оплата наличными (не меняет баланс водителя)"},
  {value: "compensation", text: "Компенсация"},
  {value: "corporate", text: "Корпоративная оплата"},
  {value: "partner_bonus_fee", text: "Комиссия партнера за бонус"},
  {value: "partner_ride_card", text: "Оплата по карте за партнёрский заказ"},
  {value: "partner_ride_cash_collected", text: "Оплата наличными за партнёрский заказ (не меняет баланс водителя)"},
  {value: "partner_ride_fee", text: "Комиссия партнера за партнёрский заказ"},
  {value: "partner_service_financial_statement", text: "Финансовая ведомость"},
  {value: "partner_service_manual", text: "Ручной платёж"},
  {value: "partner_service_other", text: "Прочее"},
  {value: "partner_service_recurrent_payment", text: "Списание по условиям работы"},
  {value: "partner_service_recurring_payment", text: "Платежи по расписанию"},
  {value: "partner_subscription_fee", text: "Комиссия партнера за смену"},
  {value: "platform_bonus_fee", text: "Комиссия платформы за бонус"},
  {value: "platform_other_carwash", text: "Прочее, оплата мойки"},
  {value: "platform_other_gas", text: "Оплата на АЗС"},
  {value: "platform_other_gas_cashback", text: "Оплата на АЗС, кэшбэк"},
  {value: "platform_other_gas_fleet_fee", text: "Оплата на АЗС, комиссия партнера"},
  {value: "platform_other_gas_tip", text: "Оплата на АЗС, чаевые"},
  {value: "platform_other_rent_childseat", text: "Аренда детского кресла"},
  {value: "platform_other_rent_childseat_vat", text: "Аренда детского кресла, НДС"},
  {value: "platform_other_referral", text: "Реферальный платёж"},
  {value: "platform_other_scout", text: "Платёж скауту"},
  {value: "platform_ride_fee", text: "Комиссия платформы за заказ"},
  {value: "platform_ride_vat", text: "Комиссия платформы за заказ, НДС"},
  {value: "promotion_discount", text: "Компенсация скидки клиента"},
  {value: "promotion_promocode", text: "Компенсация промокода клиента"},
  {value: "subscription", text: "Покупка смены водителем"},
  {value: "subscription_vat", text: "Покупка смены водителем, НДС"},
  {value: "tip", text: "Чаевые"},
  {value: "crm_manual_operation", text: "Ручная операция через CRM"},
  {value: "crm_auto_operation", text: "Автоматическая операция CRM"},
  {value: "crm_form_operation", text: "Операция через форму CRM", hidden: true},
];

const TemplateExecutionStatuses = [
  {value: "disabled", text: "Не Запланирован", selectable: false},
  {value: "scheduled", text: "Запланирован", selectable: true},
  {value: "enqueued", text: "В Очереди", selectable: false},
  {value: "executing", text: "Выполняется", selectable: false},
  {value: "completed", text: "Завершено", selectable: true},
  {value: "paused", text: "Приостановлено", selectable: true},
];

const TemplateTypes = [
  {value: "debit", text: "Расход"},
  {value: "credit", text: "Пополнение"},
];

/**
 * Периодичность списаний
 *
 * @type {({visible: boolean, text: string, value: string}|{visible: boolean, text: string, value: string}|{visible: boolean, text: string, value: string}|{visible: boolean, text: string, value: string}|{visible: boolean, text: string, value: string})[]}
 */
const SchedulerPeriods = [
  {value: "1", text: "Ежедневно", visible: true},
  {value: "2", text: "Через 2 дня", visible: false},
  {value: "3", text: "Через 3 дня", visible: false},
  {value: "4", text: "Через 4 дня", visible: false},
  {value: "5", text: "Через 5 дней", visible: false},
  {value: "6", text: "Через 6 дней", visible: false},
  {value: "7", text: "Каждую неделю", visible: false},
  {value: "8", text: "Через 8 дней", visible: false},
  {value: "9", text: "Через 9 дней", visible: false},
  {value: "10", text: "Через 10 дней", visible: false},
  {value: "11", text: "Через 11 дней", visible: false},
  {value: "12", text: "Через 12 дней", visible: false},
  {value: "13", text: "Через 13 дней", visible: false},
  {value: "14", text: "Каждые 2 недели", visible: false},
  {value: "15", text: "Через 15 дней", visible: false},
  {value: "16", text: "Через 16 дней", visible: false},
  {value: "17", text: "Через 17 дней", visible: false},
  {value: "18", text: "Через 18 дней", visible: false},
  {value: "19", text: "Через 19 дней", visible: false},
  {value: "20", text: "Через 20 дней", visible: false},
  {value: "21", text: "Каждые 3 недели", visible: false},
  {value: "22", text: "Через 22 дня", visible: false},
  {value: "23", text: "Через 23 дня", visible: false},
  {value: "24", text: "Через 24 дня", visible: false},
  {value: "25", text: "Через 25 дней", visible: false},
  {value: "26", text: "Через 26 дней", visible: false},
  {value: "27", text: "Через 27 дней", visible: false},
  {value: "28", text: "Через 28 дней", visible: false},
  {value: "29", text: "Через 29 дней", visible: false},
  {value: "30", text: "Через 30 дней", visible: false},
  {value: "31", text: "Ежемесячно", visible: true},
];

/**
 * Тип операции шаблона транзакции
 *
 * @type {({text: string, value: string}|{text: string, value: string})[]}
 */
const TemplateScheduleType = [
  {value: "regular", text: "Регулярные"},
  {value: "pattern", text: "Шаблон"},
];

/**
 * Тип обработки шаблона
 *
 * @type {({text: string, value: string}|{text: string, value: string})[]}
 */
const TemplateProcessingType = [
  {value: "limited", text: "Списание с лимитом", paymentSources: ["deposit", "balance", "summary"]},
  {value: "regular", text: "Постоянное списание", paymentSources: ["balance", "summary"]},
];

/**
 * Источник списания средств по шаблону
 *
 * @type {({text: string, value: string}|{text: string, value: string})[]}
 */
const TemplatePaymentSource = [
  {value: "deposit", text: "Депозит"},
  {value: "balance", text: "Основной"},
  {value: "summary", text: "Сводный"},
  {value: "office", text: "Офис (диспетчер)"},
];

/**
 * Категория списаний по шаблону
 *
 * @type {({text: string, value: string}|{text: string, value: string})[]}
 */
const TemplateCategory = [
  {value: "rent", text: "Аренда"},
  {value: "deposit", text: "Залоговый платеж"},
  {value: "insurance", text: "Страховой платеж"},
  {value: "fine", text: "Штрафы"},
  {value: "other", text: "Другие платежи"},
];

/**
 * Формы организаций
 *
 * @type {({id: string, title: string}|{id: string, title: string}|{id: string, title: string})[]}
 */
const UnitTypes = [
  {id: "pl", title: "Физлицо"},
  {id: "ip", title: "ИП"},
  {id: "sw", title: "Самозанятый"},
  {id: "ul", title: "Юрлицо"},
];

/**
 * Статус ТС в парке
 *
 * @type {({id: string, title: string}|{id: string, title: string}|{id: string, title: string})[]}
 */
const ParkStatuses = [
  {id: "working", title: "На линии", enabled: true},
  {id: "parked", title: "В парке", enabled: true},
  {id: "accident", title: "ДТП", enabled: true},
  {id: "parts_waiting", title: "Ожидание поставки запчастей", enabled: true},
  {id: "locksmith_repair_park", title: "Слесарный ремонт парк", enabled: true},
  {id: "locksmith_repair_contractor", title: "Слесарный ремонт подрядчики", enabled: true},
  {id: "need_body_repair", title: "Требуется кузовной ремонт", enabled: true},
  {id: "body_repair_park", title: "Кузовной ремонт парк", enabled: true},
  {id: "body_repair_contractor", title: "Кузовной ремонт подрядчики", enabled: true},
  {id: "warranty_repair", title: "Гарантийный ремонт", enabled: true},
  {id: "repair", title: "Слесарный ремонт", enabled: false}, // deprecated
  {id: "bodyRepair", title: "Кузовной ремонт", enabled: false}, // deprecated
  {id: "licenseless", title: "Подготовка к выдаче", enabled: true},
  {id: "preparing", title: "Отдел подготовки", enabled: true},
  {id: "owner_return", title: "Возврат собственнику", enabled: true},
  {id: "transfer", title: "Перегон", enabled: true},
  {id: "other", title: "Иное", enabled: true},
];

/**
 * Категории ТС
 *
 * @type {({id: string, title: string}|{id: string, title: string}|{id: string, title: string})[]}
 */
const VehicleCategories = [
  {id: "a", title: "A"},
  {id: "b", title: "B"},
  {id: "c", title: "C"},
  {id: "d", title: "D"},
  {id: "e", title: "E"},
];

/**
 * Типы ТС
 *
 * Легковой такси, Легковой такси эконом, Легковой такси комфорт, Легковой такси Комфорт+, Легковой бизнес,
 * Легковой такси старт, Легковой, Грузовой большой, Грузовой средний, Грузовой легковая, Курьерский маленький,
 * Курьерский стандарт, Эвакуатор.
 *
 * passenger taxi, passenger taxi economy, passenger taxi comfort, passenger taxi comfort +, passenger business,
 * passenger taxi start, passenger, cargo large, cargo medium, cargo passenger, express small,
 * courier standard, tow truck.
 *
 * @type {({id: string, title: string}|{id: string, title: string}|{id: string, title: string})[]}
 */
const VehicleType = [
  {id: "passenger_taxi", title: "Легковой такси"},
  {id: "passenger_taxi_economy", title: "Легковой такси эконом"},
  {id: "passenger_taxi_comfort", title: "Легковой такси комфорт"},
  {id: "passenger_taxi_comfort_plus", title: "Легковой такси Комфорт+"},
  {id: "passenger_business", title: "Легковой бизнес"},
  {id: "passenger_taxi_start", title: "Легковой такси старт"},
  {id: "passenger", title: "Легковой"},
  {id: "cargo_large", title: "Грузовой большой"},
  {id: "cargo_medium", title: "Грузовой средний"},
  {id: "cargo_passenger", title: "Грузовой легковая"},
  {id: "express_small", title: "Курьерский маленький"},
  {id: "courier_standard", title: "Курьерский стандарт"},
  {id: "tow_truck", title: "Эвакуатор"},
];

/**
 * Типы КПП
 *
 * @type {({id: string, title: string}|{id: string, title: string}|{id: string, title: string})[]}
 */
const VehicleGearboxTypes = [
  {id: "auto", title: "Автоматическая"},
  {id: "manual", title: "Механическая"},
];

/**
 * Типы салона
 *
 * @type {({id: string, title: string}|{id: string, title: string}|{id: string, title: string})[]}
 */
const VehicleSalonTypes = [
  {id: "leather", title: "Кожаный"},
  {id: "leather_covers", title: "Кожаный (чехлы)"},
  {id: "fabric", title: "Тканевый"},
  {id: "fabric_covers", title: "Тканевый (чехлы)"},
];

/**
 * Статусы договоров аренды
 *
 * @type {({id: string, title: string}|{id: string, title: string}|{id: string, title: string})[]}
 */
const RentContractStatus = [
  {id: "active", title: "Действующий"},
  {id: "closed", title: "Завершенный"},
];

/**
 * Типы договоров аренды
 *
 * @type {({id: string, title: string}|{id: string, title: string}|{id: string, title: string})[]}
 */
const RentContractType = [
  {id: "with_driver", title: "С водителем"},
];

/**
 * Типы оплаты
 *
 * @type {({id: string, title: string}|{id: string, title: string}|{id: string, title: string})[]}
 */
const PaymentMethod = [
  {id: "card", title: "Карта"},
  {id: "cash", title: "Наличные"},
  {id: "bank_transfer", title: "Оплата по реквизитам"},
  {id: "internal", title: "Внутренние"},
];

/**
 * Сервисы оплаты
 *
 * @type {({id: string, title: string}|{id: string, title: string}|{id: string, title: string})[]}
 */
const PaymentProvider = [
  {id: "sbp", title: "СБП"},
  {id: "payonline", title: "PayOnline"},
  {id: "office", title: "Офис"},
];

/**
 * Типы паспортов ТС
 *
 * @type {({id: string, title: string}|{id: string, title: string}|{id: string, title: string})[]}
 */
const PassportType = [
  {id: "electronic", title: "Электронный"},
  {id: "paper", title: "Бумажный"},
];

/**
 * Виды фотоконтроля
 *
 * @type {({id: string, title: string}|{id: string, title: string}|{id: string, title: string})[]}
 */
const PhotoControlMode = [
  {id: "passenger_taxi", title: "Пассажирское такси"},
  {id: "tow_truck", title: "Эвакуатор"},
];

/**
 * Типы печатных форм
 *
 * @type {({id: string, title: string}|{id: string, title: string}|{id: string, title: string})[]}
 */
const PrintFormType = [
  {id: "crm_pm_vehicle_contract", title: "Договор"},
];

/**
 * Виды осмотров
 *
 * @type {({id: string, title: string}|{id: string, title: string}|{id: string, title: string})[]}
 */
const InspectionType = [
  {id: "return", title: "Возврат ТС"},
  {id: "issue", title: "Выдача ТС"},
  {id: "review", title: "Осмотр"},
  {id: "check", title: "ТО"},
  {id: "repair", title: "Ремонт"},
  {id: "checkAndRepair", title: "ТО + Ремонт"},
  {id: "photo_control", title: "Фотоконтроль"},
  {id: "changeStatus", title: "Смена статуса"},
];

/**
 * Виды ОСАГО
 *
 * @type {({id: string, title: string}|{id: string, title: string}|{id: string, title: string})[]}
 */
const OsagoPolicyType = [
  {id: "osgo_only", title: "Только ОСАГО"},
];


const TrackerEventCodes = [
  {id: "inzone", title: "Вход в гео-зоны"},
  {id: "outzone", title: "Выход из гео-зоны"},
  {id: "long_idle", title: "Простой"},
  {id: "signal_lost", title: "Потеряна связь с маячком"},
  {id: "offline", title: "Офлайн"},
  {id: "lowpower", title: "Низкий заряд батареи"},
  {id: "parking", title: "Несанкционированное движение"},
  {id: "detach", title: "Отключение"},
  {id: "battery_off", title: "Отключение внешнего питания"},
];


const AccidentTransactionTypes = [
  {id: "PLUS", title: "Пополнение"},
  {id: "MINUS", title: "Списание"},
];

export {
  WorkStatuses,
  ActivityStatuses,
  CarReturnReasons,
  TransferTypes,
  TransferConnections,
  Periodicity,
  AppProps,
  DriverPhotoControlTypes,
  PhotoControlStatuses,
  DriverTransactionCategories,
  TemplateExecutionStatuses,
  TemplateTypes,
  SchedulerPeriods,
  TemplateScheduleType,
  TemplateProcessingType,
  TemplatePaymentSource,
  TemplateCategory,
  UnitTypes,
  ParkStatuses,
  VehicleCategories,
  VehicleGearboxTypes,
  VehicleSalonTypes,
  RentContractStatus,
  RentContractType,
  PaymentMethod,
  PaymentProvider,
  VehicleType,
  PassportType,
  PhotoControlMode,
  PrintFormType,
  InspectionType,
  OsagoPolicyType,
  TrackerEventCodes,
  AccidentTransactionTypes,
  LegalStatuses
}