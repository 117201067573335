import React, {useContext, useEffect, useState} from "react";
import moment from "moment";
import {apiClient} from "../../../services/ApiClient";
import {windowService} from "../../../services/WindowService";
import {
  AutoComplete,
  Button,
  Checkbox,
  Col,
  Collapse,
  Comment,
  DatePicker,
  Input,
  message,
  Modal,
  PageHeader,
  Row,
  Select,
  Space, Switch,
  Table,
  Tooltip
} from "antd";
import {DeleteOutlined, LinkOutlined, QuestionOutlined, ReloadOutlined, EditFilled, QuestionCircleOutlined} from "@ant-design/icons";
import CommentEditor from "./CommentEditor";
import UserProfileContext from "../../../services/UserProfileContext";
import CancelButton from "../../buttons/CancelButton";
import './DriversPage.css'
import {daDataService} from "../../../services/DaDataService";
import LinkButton from "../../buttons/LinkButton";

const { Panel } = Collapse;
const { Option } = Select;
const { Search } = Input;

const rowStyle = {padding: '4px'};

export default function DriverCandidatesPage() {
  const userProfile = useContext(UserProfileContext);

  let [dataSourceVersion, setDataSourceVersion] = useState(0);
  let [dataSource, setDataSource] = useState([]);
  let [dialogModel, setDialogModel] = useState(null);
  let [comments, setComments] = useState([]);
  let [reports, setReports] = useState([]);
  let [modelCreateAccepted, setModelCreateAccepted] = useState(false);
  let [loading, setLoading] = useState(false);
  let [taxiParks, setTaxiParks] = useState([]);
  let [workRules, setWorkRules] = useState([]);
  let [workRulesLoading, setWorkRulesLoading] = useState(false);
  let [vehiclesLoading, setVehiclesLoading] = useState(false);
  let [vehicles, setVehicles] = useState([]);
  let [valuesLoading, setValuesLoading] = useState(false);
  let [licenseCountries, setLicenseCountries] = useState([]);
  let [reportRegions, setReportRegions] = useState([]);
  let [driverCandidateReportLoading, setDriverCandidateReportLoading] = useState(false);
  let [suggestions, setSuggestions] = useState({});
  let [search, setSearch] = useState([]);
  let [regions, setRegions] = useState([]);
  let [selectedRegionId, setSelectedRegionId] = useState([]);

  const columns = [
    {
      title: "",
      align: "center",
      width: 30,
    },
    {
      title: '',
      width: 50,
      render: (text, record, index) => {
        return (
          <Button icon={<EditFilled/>} onClick={() => {showRequestEditDialog(record);}} type="link"/>
        )
      }
    },
    {
      title: '№',
      dataIndex: 'id',
      width: 50,
      sorter: (a, b) => {
        return a.id - b.id;
      },
    },
    {
      title: 'ФИО',
      width: 300,
      dataIndex: 'fio',
      align: "center",
      sorter: (a, b) => {
        const aVal = a.fio || `${a.last_name} ${a.first_name} ${a.second_name}`;
        const bVal = b.fio || `${b.last_name} ${b.first_name} ${b.second_name}`;
        return aVal.localeCompare(bVal);
      },
      render: (text, record, index) => {
        let val = record.fio;
        if(!val) {
          val = `${record.last_name} ${record.first_name} ${record.second_name}`;
        }
        return record.taxi_driver
          ? (
            <Button
              type={"link"}
              style={{padding: 0}}
              onClick={() => {
                windowService.openRouteWindow(`cp/drivers/${record.taxi_driver.id}`);
              }}
            >
              {
                <>
                  <div>{val}</div>
                </>
              }
            </Button>
          ) : val;
      },
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      align: "center",
      sorter: (a, b) => {
        return a.phone.localeCompare(b.car_number);
      },
    },
    {
      title: 'Доп. телефон',
      dataIndex: 'other_phone',
      align: "center",
      sorter: (a, b) => {
        return a.other_phone.localeCompare(b.car_number);
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      align: "center",
      sorter: (a, b) => {
        return a.email.localeCompare(b.car_info);
      },
    },
    {
      title: 'Адрес проживания',
      dataIndex: 'actual_address',
    },
    {
      title: 'ФИО родственника/друга',
      dataIndex: 'contact_fio',
    },
    {
      title: 'Телефон родственника/друга',
      dataIndex: 'contact_address',
    },
    {
      title: 'Дата создания',
      dataIndex: 'create_dt',
      align: "center",
      render: (text, record, index) => {
        return `${moment(record.create_dt).format('DD.MM.YYYY HH:mm')}`;
      },
      sorter: (a, b) => {
        return moment(a.create_dt).valueOf() - moment(b.create_dt).valueOf();
      },
    },
    {
      title: "",
      align: "center",
      width: 20,
    }
  ];

  const showRequestEditDialog = (record) => {
    loadComments(record);
    loadReports(record);
    loadReportRegions(record);

    setDialogModel(record);
  };

  const validateDialog = () => {
    return new Promise((resolve, reject) => {
      if(!dialogModel.reg_address) {
        message.warn("Необходимо заполнить адрес регистрации");
        return;
      }
      resolve();
    })
  };

  const saveRequestEditDialog = () => {
    // upload dialogModel changes
    if(dialogModel.id > 0) {
      apiClient.updateDriverCandidate(dialogModel.id, {...dialogModel, id: null})
        .then((res) => {
          // change in dataSource
          setDataSource([...dataSource.map(e => e.id !== res.record.id ? e : res.record)]);
          message.success("Данные сохранены");
        })
        .catch(err => {
          message.error('Ошибка, не удалось сохранить данные');
        });
    } else {
      apiClient.createDriverCandidate({...dialogModel, id: null})
        .then((res) => {
          // update in dataSource
          setDataSource([res.record, ...dataSource]);
          setDialogModel(res.record);

          message.success("Данные сохранены");
        })
        .catch(err => {
          message.error('Ошибка, не удалось сохранить данные');
        });
    }
  };

  const loadComments = (record) => {
    apiClient.getDriverCandidateComments(record.id)
      .then(res => {
        setComments(res.records);
      })
      .catch(err => {
        message.error("Не удалось загрузить комментарии")
      });
  };

  const loadReports = (record) => {
    apiClient.getDriverCandidateReports(record.id)
      .then(res => {
        setReports(res.records);
      })
      .catch(err => {
        message.error("Не удалось загрузить отчеты")
      });
  };

  const loadReportRegions = (record) => {
    apiClient.getDriverCandidateReportRegions(record.id)
      .then(res => {
        setReportRegions(res.records);
      })
      .catch(err => {
        message.error("Не удалось загрузить список филиалов")
      });
  };

  const handleCommentDelete = (record, comment) => {
    apiClient.removeDriverCandidateComment(record.id, comment.id)
      .then(() => {
        loadComments(record);
      })
      .catch(err => {
        message.error("Ошибка");
      });
  };

  const handleRunReports = () => {
    if(!dialogModel.birth_dt) {
      message.warn("Не указана дата рождения");
      return;
    }
    if(!dialogModel.passport_number || !dialogModel.passport_issue_dt) {
      message.warn("Не указаны данные паспорта");
      return;
    }
    if(!dialogModel.check_regions || dialogModel.check_regions < 1) {
      message.warn("Не указаны филиалы для поиска");
      return;
    }
    Modal.confirm({
      title:  "Подтверждение",
      content: (
        <>
          <p>
            Сохранить изменения и запустить формирование отчетов?
          </p>
          <p>
            <b>Внимание!</b> Убедитсь, что атрибут "Гражданин РФ" проверен
          </p>
        </>
      ),
      icon: <QuestionOutlined/>,
      visible: true,
      onOk() {
        apiClient.updateDriverCandidate(dialogModel.id, {...dialogModel, id: null})
          .then(() => {
            // change in dataSource
            setDataSource([...dataSource.map(e => e.id !== dialogModel.id ? e : dialogModel)]);

            message.success("Данные сохранены");

            apiClient.runDriverCandidateReports(dialogModel.id)
              .then(() => {
                message.success("Формирование отчетов запущено");
                loadReports(dialogModel)
              })
              .catch(err => {
                message.error("Ошибка");
              });
          })
          .catch(err => {
            message.error('Ошибка, не удалось сохранить данные');
          });
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    apiClient.getDriverCandidates({
      search: search,
      regionIds: selectedRegionId
    })
      .then(res => {
        setDataSource(res.records);
      })
      .catch(err => { message.error('Ошибка, не удалось загрузить данные'); })
      .finally(() => { setLoading(false); });
  }, [search, selectedRegionId, dataSourceVersion]);

  useEffect(() => {
    apiClient.getParks()
      .then(res => { setTaxiParks(res.records); })
      .catch(err => { message.error(err); });
    setValuesLoading(true);
    apiClient.getValueLists(["driver_license_countries"])
      .then(res => { setLicenseCountries(res.records["driver_license_countries"]) })
      .catch(err => { message.error(err); })
      .finally(() => { setValuesLoading(false) });
    apiClient.getRegions({"order": "title"})
      .then(res => { setRegions(res.records) })
      .catch(err => { message.error(err) });
  }, []);

  useEffect(() => {
    console.log(dialogModel);
  }, [dialogModel]);

  const handleCreateDriver = () => {
    let checks = {
      "birth_dt": "Заполните дату рождения",
      "taxi_park_id": "Выберите парк",
      "vehicle_id": "Выберите ТС",
      "work_rule_uid": "Выберите условия работы",
      "licence_number": "Укажите номер ВУ",
      "licence_dt": "Укажите дату выдачи ВУ",
      "license_expiry_dt": "Укажите срок действия ВУ",
      "license_experience_dt": "Укажите стаж",
      "passport_number": "Укажите серию/номер паспорта",
      "passport_issue_dt": "Укажите дату паспорта",
    };
    let errs = Object.keys(checks).filter(key => !dialogModel[key]);
    if(errs.length > 0) {
      errs.forEach(key => message.error(checks[key]));
      return;
    }
    setLoading(true);
    apiClient.updateDriverCandidate(dialogModel.id, {...dialogModel, id: null})
      .then(() => {
        // update dataSource
        setDataSource([...dataSource.map(e => e.id !== dialogModel.id ? e : dialogModel)]);
        // create driver
        apiClient.acceptDriverCandidate(dialogModel.id, {
          taxi_park_id: dialogModel.taxi_park_id,
          vehicle_id: dialogModel.vehicle_id,
          work_rule_uid: dialogModel.work_rule_uid,
          is_selfemployed: dialogModel.is_selfemployed,
        })
          .then((res) => {
            // change in dataSource
            windowService.openRouteWindow(`cp/drivers/${res.record.id}`);

            setDialogModel(null);
            setModelCreateAccepted(false);
          })
          .catch(err => {
            message.error('Ошибка: ' + err);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(err => {
        message.error('Ошибка, не удалось сохранить данные');
        setLoading(false);
      });
  };

  const handleDeleteReport = record => {
    Modal.confirm({
      title:  "Подтверждение",
      content: `Удалить отчет?`,
      icon: <QuestionOutlined/>,
      visible: true,
      onOk() {
        apiClient.deleteDriverCandidateReport(dialogModel.id, record.id)
          .then(() => {
            loadReports(dialogModel);
            message.success("Отчет удален");
          })
          .catch(err => {
            message.error('Ошибка, не удалось выполнить операцию');
          });
      }
    });
  };

  const handleOpenReport = record => {
    setDriverCandidateReportLoading(true);
    apiClient.getDriverCandidateReport(dialogModel.id, record.id)
      .then(res => {
        window.open(res.link, '_blank').focus();
      })
      .catch(err => message.error(err))
      .finally(() => {
        setDriverCandidateReportLoading(false);
      })
  };

  const checkDuplicates = phone => {
    apiClient.getDriverCandidates({phone})
      .then(res => {
        if (res.records
          && res.records.length > 0
          && res.records[0].id !== dialogModel.id) {
          Modal.confirm({
            title: "Проверка",
            content: `По указанному номеру найдена другая запись. Хотите редактировать ее?`,
            icon: <QuestionOutlined/>,
            visible: true,
            onOk() {
              setDialogModel(res.records[0]);
            }
          });
        }
      })
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        title={
          <Tooltip
            placement="top"
            title="Кандидаты - это водители, которые планируют работать в нашем таксопарке. 
            Заявки  на эту страницу они отправляют с веб-страницы , также  кандидата можно добавить через CRM на этой странице."
          >
            Кандидаты <QuestionCircleOutlined />
          </Tooltip>
        }
        extra={
          <div style={{textAlign: "right"}}>
            Всего: {dataSource.length}
            <Button type="link" icon={<LinkOutlined/>}
                    onClick={() => {
                      Modal.info({
                        title:  "Ссылка на страницу",
                        width: 800,
                        content:
                          <div style={{width: 500}}>
                            <LinkButton
                              label={`${process.env.REACT_APP_WEB_HOST}/candidates/${userProfile.tenant.code}`}
                              onClick={() => {
                                windowService.openRouteWindow(`candidates/${userProfile.tenant.code}`);
                              }}
                            />
                          </div>,
                          // <a href={`${process.env.REACT_APP_WEB_HOST}/candidates/${userProfile.tenant.code}`}>{`${process.env.REACT_APP_WEB_HOST}/candidates/${userProfile.tenant.code}`}</a>,
                        visible: true,
                      });
                    }}>
              Ссылка на страницу
            </Button>
          </div>
        }
      />
      <div style={{width: '100%'}}>
          <div style={{width: '60%', float: 'left'}} >
            <Space direction={"horizontal"} wrap>
              <Search
                enterButton
                placeholder={"Поиск по ФИО и телефону"}
                onSearch={(value, event) => {
                  setSearch(value);
                }}
                allowClear={true}
                style={{width: 400}}
              />
              <Select
                allowClear
                mode="multiple"
                placeholder="Филиал"
                value={selectedRegionId}
                style={{width: 300}}
                onChange={(data) => { setSelectedRegionId(data); }}
                filterOption={(input, option) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {
                  regions.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
                }
              </Select>
            </Space>
          </div>
          <div style={{width: '40%', float: 'right'}} >
            <Space style={{float: "right", marginBottom: 16}} direction="horizontal">
              <Button type="primary" onClick={() => { setDialogModel({id: 0}) }}>Добавить</Button>
            </Space>
          </div>
      </div>
      <div style={{margin: '10px 0'}}>
        <Table
          dataSource={dataSource}
          columns={columns}
          size='small'
          rowKey="id"
          loading={loading}
          pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [50, 100, 200]}}
          scroll={{x: 1800}}
          sticky
        />
      </div>

      {
        dialogModel && (
          <Modal
            title={`Кандидат`}
            visible={dialogModel !== null}
            onOk={() => {
              validateDialog()
                .then(() => {
                  saveRequestEditDialog();
                  setDialogModel(null);
                });
            }}
            onCancel={() => { setDialogModel(null) }}
            className="size-for-middle-window"
            style={{ top: 20 }}
            footer={[
              <Button
                type="primary"
                loading={loading}
                onClick={() => {
                  validateDialog()
                    .then(() => {
                      saveRequestEditDialog();
                    });
                }}
              >
                Сохранить
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={loading}
                onClick={() => {
                  validateDialog()
                    .then(() => {
                      saveRequestEditDialog();
                      setDialogModel(null);
                    });
                }}
              >
                Сохранить и закрыть
              </Button>,
              <CancelButton key="back" onClick={() => { setDialogModel(null) }} value="Отмена"/>,
            ]}
          >

            <>
              <Collapse defaultActiveKey={['1']}>
                <Panel header="Этап 1" key="1">
                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>Фамилия</Col>
                    <Col span={18}>
                      <AutoComplete
                        allowClear={true}
                        placeholder="Фамилия"
                        options={suggestions.last_name || []}
                        value={dialogModel?.last_name}
                        style={{width: '100%'}}
                        onChange={(data) => {
                          setDialogModel({...dialogModel, last_name: data});
                          setSuggestions({...suggestions, last_name: []});
                        }}
                        onSearch={(text) => {
                          if(text && text.length > 2 && !text.endsWith(" ")) {
                            daDataService.suggestFio(text)
                              .then(res => {
                                setSuggestions({...suggestions, last_name: res.suggestions.map(el => {return {value: el.value}})});
                              })
                          }
                        }}
                      />
                    </Col>
                  </Row>

                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>Имя</Col>
                    <Col span={18}>
                      <AutoComplete
                        allowClear={true}
                        placeholder="Имя"
                        options={suggestions.first_name || []}
                        value={dialogModel?.first_name}
                        style={{width: '100%'}}
                        onChange={(data) => {
                          setDialogModel({...dialogModel, first_name: data});
                          setSuggestions({...suggestions, first_name: []});
                        }}
                        onSearch={(text) => {
                          if(text && text.length > 2 && !text.endsWith(" ")) {
                            daDataService.suggestFio(text)
                              .then(res => {
                                setSuggestions({...suggestions, first_name: res.suggestions.map(el => {return {value: el.value}})});
                              })
                          }
                        }}
                      />
                    </Col>
                  </Row>

                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>Отчество</Col>
                    <Col span={18}>
                      <AutoComplete
                        allowClear={true}
                        placeholder="Отчество"
                        options={suggestions.second_name || []}
                        value={dialogModel?.second_name}
                        style={{width: '100%'}}
                        onChange={(data) => {
                          setDialogModel({...dialogModel, second_name: data});
                          setSuggestions({...suggestions, second_name: []});
                        }}
                        onSearch={(text) => {
                          if(text && text.length > 2 && !text.endsWith(" ")) {
                            daDataService.suggestFio(text)
                              .then(res => {
                                setSuggestions({...suggestions, second_name: res.suggestions.map(el => {return {value: el.value}})});
                              })
                          }
                        }}
                      />
                    </Col>
                  </Row>

                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>Телефон</Col>
                    <Col span={18}>
                      <Input
                        allowClear={true}
                        prefix={"+"}
                        placeholder="Телефон"
                        value={dialogModel ? dialogModel.phone : ""}
                        onChange={(e) => {
                          setDialogModel({...dialogModel, phone: e.target.value});
                          if(e.target.value && e.target.value.length >= 10) {
                            checkDuplicates(e.target.value);
                          }
                        }}/>
                    </Col>
                  </Row>

                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>Доп. телефон</Col>
                    <Col span={18}>
                      <Input
                        allowClear={true}
                        prefix={"+"}
                        placeholder="Доп. телефон"
                        value={dialogModel ? dialogModel.other_phone : ""}
                        onChange={(e) => { setDialogModel({...dialogModel, other_phone: e.target.value}) }}/>
                    </Col>
                  </Row>

                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>Email</Col>
                    <Col span={18}>
                      <AutoComplete
                        allowClear={true}
                        placeholder="Email"
                        options={suggestions.email || []}
                        value={dialogModel?.email}
                        style={{width: '100%'}}
                        onChange={(data) => {
                          setDialogModel({...dialogModel, email: data});
                          setSuggestions({...suggestions, email: []});
                        }}
                        onSearch={(text) => {
                          if(text && text.length > 2 && !text.endsWith(" ")) {
                            daDataService.suggestEmail(text)
                              .then(res => {
                                setSuggestions({...suggestions, email: res.suggestions.map(el => {return {value: el.value}})});
                              })
                          }
                        }}
                      />
                    </Col>
                  </Row>

                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>Адрес проживания</Col>
                    <Col span={18}>
                      <AutoComplete
                        allowClear={true}
                        placeholder="Адрес проживания"
                        options={suggestions.actual_address || []}
                        value={dialogModel?.actual_address}
                        style={{width: '100%'}}
                        onChange={(data) => {
                          setDialogModel({...dialogModel, actual_address: data});
                          setSuggestions({...suggestions, actual_address: []});
                        }}
                        onSearch={(text) => {
                          if(text && text.length > 2 && !text.endsWith(" ")) {
                            daDataService.suggestAddress(text)
                              .then(res => {
                                setSuggestions({...suggestions, actual_address: res.suggestions.map(el => {return {value: el.value}})});
                              })
                          }
                        }}
                      />
                    </Col>
                  </Row>

                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>Адрес регистрации</Col>
                    <Col span={18}>
                      <AutoComplete
                        allowClear={true}
                        placeholder="Адрес регистрации"
                        options={suggestions.reg_address || []}
                        value={dialogModel?.reg_address}
                        style={{width: '100%'}}
                        onChange={(data) => {
                          setDialogModel({...dialogModel, reg_address: data});
                          setSuggestions({...suggestions, reg_address: []});
                        }}
                        onSearch={(text) => {
                          if(text && text.length > 2 && !text.endsWith(" ")) {
                            daDataService.suggestAddress(text)
                              .then(res => {
                                setSuggestions({...suggestions, reg_address: res.suggestions.map(el => {return {value: el.value}})});
                              })
                          }
                        }}
                      />
                    </Col>
                  </Row>

                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>ФИО родственника/друга</Col>
                    <Col span={18}>
                      <AutoComplete
                        allowClear={true}
                        placeholder="ФИО родственника/друга"
                        options={suggestions.contact_fio || []}
                        value={dialogModel?.contact_fio}
                        style={{width: '100%'}}
                        onChange={(data) => {
                          setDialogModel({...dialogModel, contact_fio: data});
                          setSuggestions({...suggestions, contact_fio: []});
                        }}
                        onSearch={(text) => {
                          if(text && text.length > 2 && !text.endsWith(" ")) {
                            daDataService.suggestFio(text)
                              .then(res => {
                                setSuggestions({...suggestions, contact_fio: res.suggestions.map(el => {return {value: el.value}})});
                              })
                          }
                        }}
                      />
                    </Col>
                  </Row>

                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>Телефон родственника/друга</Col>
                    <Col span={18}>
                      <Input
                        allowClear={true}
                        placeholder="Телефон родственника/друга"
                        value={dialogModel ? dialogModel.contact_address : ""}
                        onChange={(e) => { setDialogModel({...dialogModel, contact_address: e.target.value}) }}/>
                    </Col>
                  </Row>

                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>Филиал</Col>
                    <Col span={18}>
                      <Select
                        showSearch
                        allowClear
                        style={{width: '100%'}}
                        value={dialogModel.work_region_id}
                        onChange={(value) => {
                          setDialogModel({...dialogModel, work_region_id: value})
                        }}
                        filterOption={(input, option) => {
                          return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }}
                      >
                        {
                          regions.map(el => <Option key={el.id} value={el.id}>{`${el.title}`}</Option>)
                        }
                      </Select>
                    </Col>
                  </Row>
                </Panel>
                <Panel header="Этап 2" key="2" disabled={dialogModel.id === 0}>
                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>Дата рождения</Col>
                    <Col span={18}>
                      <DatePicker
                        allowClear={false}
                        format="DD.MM.YYYY"
                        placeholder="Дата рождения"
                        value={dialogModel.birth_dt ? moment(dialogModel.birth_dt, "YYYY-MM-DD") : null}
                        onChange={(val) => { setDialogModel({...dialogModel, birth_dt: val ? val.format("YYYY-MM-DD") : null }) }}
                        style={{width: "100%"}}/>
                    </Col>
                  </Row>
                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>ФССП</Col>
                    <Col span={18}>
                      <Input
                        allowClear={true}
                        placeholder="ФССП"
                        value={dialogModel ? dialogModel.fssp : ""}
                        onChange={(e) => { setDialogModel({...dialogModel, fssp: e.target.value}) }}/>
                    </Col>
                  </Row>
                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>ВУ</Col>
                    <Col span={18}>
                      <Space direction={"horizontal"} wrap>
                        <Input
                          allowClear={true}
                          placeholder="Номер"
                          value={dialogModel ? dialogModel.licence_number : ""}
                          onChange={(e) => { setDialogModel({...dialogModel, licence_number: e.target.value}) }}/>
                        <DatePicker
                          allowClear={false}
                          format="DD.MM.YYYY"
                          placeholder="Дата выдачи"
                          value={dialogModel.licence_dt ? moment(dialogModel.licence_dt, "YYYY-MM-DD") : null}
                          onChange={(val) => { setDialogModel({...dialogModel, licence_dt: val ? val.format("YYYY-MM-DD") : null }) }}
                          style={{width: 220}}/>
                      </Space>
                    </Col>
                  </Row>
                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>Срок действия</Col>
                    <Col span={18}>
                      <DatePicker
                        allowClear={false}
                        format="DD.MM.YYYY"
                        style={{width: "100%"}}
                        value={dialogModel.license_expiry_dt ? moment(dialogModel.license_expiry_dt, "YYYY-MM-DD") : null}
                        onChange={(val) => { setDialogModel({...dialogModel, license_expiry_dt: val ? val.format("YYYY-MM-DD") : null }) }}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>Страна выдачи</Col>
                    <Col span={18}>
                      <Select
                        showSearch
                        allowClear
                        style={{width: '100%'}}
                        loading={valuesLoading}
                        value={dialogModel.license_country}
                        onChange={(value) => {
                          setDialogModel({...dialogModel, license_country: value})
                        }}
                        filterOption={(input, option) => {
                          return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }}
                      >
                        {
                          licenseCountries.map(el => <Option key={el.id} value={el.id}>{el.title}</Option>)
                        }
                      </Select>
                    </Col>
                  </Row>
                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>Филиалы поиска</Col>
                    <Col span={18}>
                      <Select
                        mode={"multiple"}
                        showSearch
                        allowClear
                        style={{ width: 250 }}
                        loading={valuesLoading}
                        value={dialogModel.check_regions || []}
                        placeholder="Филиалы поиска"
                        onChange={(value) => {
                          setDialogModel({...dialogModel, check_regions: value})
                        }}
                        filterOption={(input, option) => {
                          return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }}
                      >
                        {
                          reportRegions.map(s => <Option key={s.id} value={s.id}>{s.title}</Option>)
                        }
                      </Select>
                    </Col>
                  </Row>
                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>Стаж c</Col>
                    <Col span={18}>
                      <DatePicker
                        allowClear={false}
                        format="DD.MM.YYYY"
                        style={{width: "100%"}}
                        value={dialogModel.license_experience_dt ? moment(dialogModel.license_experience_dt, "YYYY-MM-DD") : null}
                        onChange={(val) => { setDialogModel({...dialogModel, license_experience_dt: val ? val.format("YYYY-MM-DD") : null }) }}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>Лишения</Col>
                    <Col span={18}>
                      <Input
                        allowClear={true}
                        placeholder="Лишения"
                        value={dialogModel ? dialogModel.licence_violations : ""}
                        onChange={(e) => { setDialogModel({...dialogModel, licence_violations: e.target.value}) }}/>
                    </Col>
                  </Row>
                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>Опыт в Яндекс</Col>
                    <Col span={18}>
                      <Input
                        allowClear={true}
                        placeholder="Опыт в Яндекс"
                        value={dialogModel ? dialogModel.yandex_experience : ""}
                        onChange={(e) => { setDialogModel({...dialogModel, yandex_experience: e.target.value}) }}/>
                    </Col>
                  </Row>
                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>Паспорт</Col>
                    <Col span={18}>
                      <Space direction={"horizontal"}>
                        <Input
                          allowClear={true}
                          placeholder="Серия/Номер"
                          value={dialogModel ? dialogModel.passport_number : ""}
                          onChange={(e) => { setDialogModel({...dialogModel, passport_number: e.target.value}) }}/>
                        <DatePicker
                          allowClear={false}
                          format="DD.MM.YYYY"
                          placeholder="Дата выдачи"
                          value={dialogModel.passport_issue_dt ? moment(dialogModel.passport_issue_dt, "YYYY-MM-DD") : null}
                          onChange={(val) => { setDialogModel({...dialogModel, passport_issue_dt: val ? val.format("YYYY-MM-DD") : null }) }}
                          style={{width: 220}}/>
                      </Space>
                    </Col>
                  </Row>
                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>Паспорт действителен</Col>
                    <Col span={18}>
                      <Checkbox
                        checked={dialogModel.passport_valid}
                        onChange={(e) => {
                          setDialogModel({...dialogModel, passport_valid: e.target.checked });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>Гражданин РФ</Col>
                    <Col span={18}>
                      <Checkbox
                        checked={dialogModel.russian_citizen}
                        onChange={(e) => {
                          setDialogModel({...dialogModel, russian_citizen: e.target.checked });
                        }}
                      />
                    </Col>
                  </Row>
                </Panel>
                {
                  userProfile?.tenant?.scopes.find(scope => scope === "candidates:spectrum_reports")
                    ? (
                      <Panel header="Полная проверка кандидата" key="4" disabled={dialogModel.id === 0}>
                        <Space direction={"horizontal"} style={{marginBottom: 10}}>
                          {
                            (!reports || reports.length < 1)
                              ? (
                                <>
                                  <Button onClick={() => { handleRunReports() }}>Сформировать</Button>
                                </>
                              ) : null
                          }
                          <Button icon={<ReloadOutlined />} onClick={() => { loadReports(dialogModel) }}>Обновить</Button>
                        </Space>
                        <Table
                          loading={driverCandidateReportLoading}
                          sticky
                          dataSource={reports}
                          columns={[
                            {
                              title: "",
                              align: "center",
                              width: 30,
                            },
                            {
                              title: '',
                              render: (text, record, index) => {
                                let buttons = [
                                  <Button icon={<DeleteOutlined/>} onClick={() => handleDeleteReport(record)} type="link"/>,
                                ];
                                if(record.provider_report_id) {
                                  buttons.push(
                                    <Button icon={<LinkOutlined/>} onClick={() => handleOpenReport(record)} type="link"/>
                                  );
                                }
                                return (
                                  <Space direction="horizontal">
                                    {buttons}
                                  </Space>
                                );
                              },
                            },
                            {
                              title: 'Дата создания',
                              dataIndex: 'create_dt',
                              render: (text, record, index) => {
                                return `${moment(record.create_dt).format('DD.MM.YYYY HH:mm')}`;
                              },
                              sorter: (a, b) => {
                                return moment(a.create_dt).valueOf() - moment(b.create_dt).valueOf();
                              },
                            },
                            {
                              title: 'Тип отчета',
                              render: (text, record, index) => {
                                return record.report_type.title;
                              },
                            },
                            {
                              title: 'Статус',
                              dataIndex: 'status_title',
                            },
                            {
                              title: "",
                              align: "center",
                              width: 20,
                            }
                          ]}
                          size='small'
                          rowKey="id"
                          pagination={{position: 'topRight', defaultPageSize: 50, pageSizeOptions: [50, 100, 200]}}
                        />
                      </Panel>
                    ) : null
                }
                <Panel header="Создание водителя" key="3" disabled={dialogModel.id === 0 || dialogModel.accepted}>
                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>Парк</Col>
                    <Col span={18}>
                      <Select
                        showSearch
                        allowClear
                        value={dialogModel.taxi_park_id}
                        style={{width: '100%'}}
                        onChange={(value) => {
                          let park = taxiParks.find(el => el.id === value);
                          if(park) {
                            setWorkRulesLoading(true);
                            apiClient.getParkWorkRules(park.client_id, park.api_key, park.park_id)
                              .then(res => {
                                setWorkRules(res.records.filter(el => park.work_rules.find(pwr => pwr === el.id)));
                              })
                              .catch(err => { message.error(err); })
                              .finally(() => { setWorkRulesLoading(false) });
                            setVehiclesLoading(true);
                            apiClient.getVehicles({owned: true, park: park.id}, "minimal")
                              .then(res => { setVehicles(res.records); })
                              .catch(err => message.error(err))
                              .finally(() => { setVehiclesLoading(false) });
                          }
                          setDialogModel({...dialogModel, taxi_park_id: value})
                        }}
                        filterOption={(input, option) => {
                          return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }}
                      >
                        {
                          taxiParks.map(el => <Option key={el.id} value={el.id}>{el.title}</Option>)
                        }
                      </Select>
                    </Col>
                  </Row>
                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>Условия работы</Col>
                    <Col span={18}>
                      <Select
                        showSearch
                        allowClear
                        style={{width: '100%'}}
                        loading={workRulesLoading}
                        value={dialogModel.work_rule_uid}
                        onChange={(value) => {
                          setDialogModel({...dialogModel, work_rule_uid: value})
                        }}
                        filterOption={(input, option) => {
                          return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }}
                      >
                        {
                          workRules.map(el => <Option key={el.id} value={el.id}>{el.name}</Option>)
                        }
                      </Select>
                    </Col>
                  </Row>
                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>Выберите ТС</Col>
                    <Col span={18}>
                      <Select
                        allowClear
                        showSearch
                        style={{width: '100%'}}
                        loading={vehiclesLoading}
                        value={dialogModel.vehicle_id}
                        filterOption={(input, option) => {
                          return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }}
                        onChange={(value) => {
                          setDialogModel({...dialogModel, vehicle_id: value})
                        }}
                      >
                        {
                          vehicles?.map((el) => <Option key={el.id}>{`${el.brand} ${el.model} ${el.reg_number}`}</Option>)
                        }
                      </Select>
                    </Col>
                  </Row>
                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>Парковый самозанятый</Col>
                    <Col span={18}>
                      <Switch
                        title={"Парковый самозанятый"}
                        onChange={(checked) => { setDialogModel({...dialogModel, is_selfemployed: checked }); }}
                        checked={dialogModel.is_selfemployed}/>
                    </Col>
                  </Row>
                  <Row gutter={[0, 16]} style={rowStyle}>
                    <Col span={6}>&nbsp;</Col>
                    <Col span={18}>
                      <Space style={{margin: '10px 0', width: '100%'}} direction={"horizontal"}>
                        <Checkbox
                          checked={modelCreateAccepted}
                          onChange={(e) => {
                            setModelCreateAccepted(e.target.checked);
                          }}
                        >
                          Одобрен
                        </Checkbox>
                        <Button disabled={!modelCreateAccepted} type="primary" onClick={handleCreateDriver} loading={loading}>
                          Создать водителя
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </>

            {
              dialogModel && dialogModel.id > 0
                ? (
                  <div>
                    <div style={{marginTop: '10px'}}>
                      <h3>Комментарии</h3>
                    </div>

                    {
                      comments.length > 0
                        ? comments.map(e =>
                          <Comment
                            className="request-comment"
                            key={e.id}
                            actions={[
                              <span onClick={() => handleCommentDelete(dialogModel, e)}>
                            <span className="comment-action"><DeleteOutlined/>Удалить</span>
                          </span>
                            ]}
                            author={<span>{e.created_by}</span>}
                            content={<span>{e.message}</span>}
                            datetime={<span>{moment(e.create_dt).format('DD.MM.YYYY HH:mm')}</span>}
                          />)
                        : (<div style={{textAlign: 'center', margin: '10px'}}>Нет записей</div>)
                    }

                    <CommentEditor
                      onSubmit={(value) => {
                        return new Promise((resolve, reject) => {
                          if(!value) {
                            message.error("Необходимо ввести комментарий");
                            reject();
                            return;
                          }
                          apiClient.addDriverCandidateComment(dialogModel.id, value)
                            .then(res => {
                              resolve();
                              loadComments(dialogModel);
                            })
                            .catch(err => {
                              reject(err);
                              message.error("Ошибка")
                            });
                        });
                      }}
                    />
                  </div>
                ) : null
            }

          </Modal>
        )
      }

    </>
  );
}