import React, {useEffect, useState} from "react";
import {Divider, message, Modal, Space, Tabs, Avatar, Upload, Button} from "antd";
import {UserOutlined} from "@ant-design/icons";
import {apiClient} from "../../../../../services/ApiClient";
import PaymentsTab from "./PaymentsTab";
import TransactionsTab from "./TransactionsTab";
import AssignedTransactionTemplatesTab from "./AssignedTransactionTemplatesTab";
import BalanceInfo from "./BalanceInfo";
import AuditTab from "./AuditTab";
import DriverPropertiesTab from "./DriverPropertiesTab";
import './DriverDetails.css';
import '../../../ModalStyle.css';
import DriverFiles from "./DriverFiles";
import DriverContractsTab from "./DriverContractsTab";
import MenuButton from "../../../../buttons/MenuButton";
import PaymentsExecutionHistoryTab from "./PaymentsExecutionHistoryTab";
import {WorkStatuses} from "../../../../common/AppEnums";
import LinkButton from "../../../../buttons/LinkButton";
import {windowService} from "../../../../../services/WindowService";
import NotificationCampaings from "../../../settings/components/photocontrol/NotificationCampaings";
import MessageTemplates from "../../../settings/components/photocontrol/MessageTemplates";
import WorkflowStepsEditor from "../../../settings/components/photocontrol/WorkflowStepsEdit";
import PaymentsManualTab from "./PaymentsManualTab";
import SupplyHoursTab from "./SupplyHoursTab";

const { TabPane } = Tabs;

export default function DriverDetails({title, visible, item, onClose}) {
  let [paymentsDataSource, setPaymentsDataSource] = useState([]);
  let [driverInfoUpdate, setDriverInfoUpdate] = useState(0);
  let [dataSourceLoading, setDataSourceLoading] = useState(false);
  let [addTransactionLoading, setAddTransactionLoading] = useState(false);
  let [driverInfo, setDriverInfo] = useState(null);
  let [selectedTab, setSelectedTab] = useState("1");
  let [transactionsDataSourceStamp, setTransactionsDataSourceStamp] = useState(0);
  let [assignedTransactionTemplatesStamp, setAssignedTransactionTemplatesStamp] = useState(0);

  useEffect(() => {
    if(item) {
      console.log({item});
      setDataSourceLoading(true);
      apiClient.getDriverPaymentsBill(item.id)
        .then(res => { setPaymentsDataSource(res.records); })
        .catch(err => { message.error(err); })
        .finally(() => { setDataSourceLoading(false); });
    } else {
      setDriverInfoUpdate(0);
      setPaymentsDataSource([]);
    }
  }, [item]);

  useEffect(() => {
    if(item) {
      if(driverInfoUpdate > 0) {
        apiClient.getDriverDetails(item.id)
          .then(res => {
            setDriverInfo(res.driver);
          })
          .catch(err => {
            message.error(err);
          });
      } else {
        setDriverInfo(item);
      }
    }
  }, [item, driverInfoUpdate]);

  const addTransaction = values => {
    // show loading
    setAddTransactionLoading(true);
    // add transaction
    apiClient.addDriverTransaction(item.id, values)
      .then(res => {
        // reload transactions table
        setTransactionsDataSourceStamp(prevState => prevState + 1);
        setDriverInfoUpdate(prevState => prevState + 1);
      })
      .catch(err => {
        // show error
        message.error(err);
      })
      .finally(() => {
        // hide loading
        setAddTransactionLoading(false);
      })
  };

  const onAddPaymentTemplateExecutionPayments = (finesToPay, comment) => {
    // show loading
    setAddTransactionLoading(true);
    // add payments
    Promise.all(finesToPay.map(el => {
      return apiClient.addPaymentTemplateExecutionPayments(driverInfo.id, el.id, el.payment, comment)
        .then(res => {
          // reload transactions table
          message.info(`Шаблон ${el.title} оплачен на ${el.payment} руб`);
        })
        .catch(err => {
          // show error
          message.error(err);
        })
    }))
      .finally(() => {
        // hide loading
        setAddTransactionLoading(false);
        // reload transactions table
        setTransactionsDataSourceStamp(prevState => prevState + 1);
        setDriverInfoUpdate(prevState => prevState + 1);
        setAssignedTransactionTemplatesStamp(prevState => prevState + 1);
      })
  };

  function draw() {
    const workStatus = WorkStatuses.find(s => s.value === driverInfo.work_status);
    return (
      <>
        <Modal
          className="size-for-max-window"
          title={title}
          visible={visible}
          style={{ top: 20 }}
          onOk={() => {
            onClose(driverInfoUpdate > 0);
          }}
          onCancel={() => {
            onClose(driverInfoUpdate > 0);
            setSelectedTab("1");
          }}
        >
          {
            driverInfo
              ? (
                <Space className={'driver-details-header'} size={"small"}>

                  <Upload
                    name="avatar"
                    listType="picture-card"
                    showUploadList={false}
                    className={"avatar-uploader"}
                    accept={"image/*"}
                    action={(f) => {
                      apiClient.updateDriverAvatar(driverInfo.id, f)
                        .then((res) => {
                          setDriverInfoUpdate(prevState => prevState + 1)
                        })
                        .catch(err => {
                          message.error(err);
                        });
                    }}
                  >
                    {
                      driverInfo && driverInfo.avatar
                        ? <Avatar size={64} src={driverInfo.avatar.url } />
                        : <Avatar size={64} icon={<UserOutlined />} />
                    }
                  </Upload>

                  <div style={{display: "inline-block"}}>
                    <h2>{`${driverInfo.fio}`}</h2>
                    <Space>
                      <a target="_blank" rel="noreferrer" href={`https://t.me/${driverInfo.telegram_username ? driverInfo.telegram_username : driverInfo.phone}`}><Button ><img style={{width: 24, marginRight: 5}} src="/assets/telegram-app-48.png" alt=""/>Написать в телеграмм</Button></a>
                      {
                        driverInfo.is_working
                          ? (
                              <>
                              <span title={"Работает"} className={`badge badge--pointer badge--success`}>Работает</span>
                              <span title={workStatus.title} className={`badge badge--pointer badge--${workStatus.value}`}>{workStatus.shortText}</span>
                              </>
                          )
                          : <span title={"Уволен"} className={`badge badge--pointer badge--offline`}>Уволен</span>
                      }
                    </Space>
                  </div>
                  <h3>
                    <LinkButton
                      label={driverInfo.car_full_details}
                      onClick={() => {
                        windowService.openRouteWindow(`cp/park/vehicles/${driverInfo.current_rent?.id}`);
                      }}
                    />
                  </h3>
                  <BalanceInfo title={"Баланс"}
                               code={"balance"}
                               value={driverInfo.balance}
                               loading={addTransactionLoading}
                               driverId={driverInfo.id}
                               onAddTransaction={(values) => { addTransaction(values); }} />
                  <Divider type={"vertical"}/>
                  <BalanceInfo title={"Депозит"}
                               code={"deposit"}
                               value={`${driverInfo.deposit_paid}/${driverInfo.deposit_balance}`}
                               maxCredit={driverInfo.deposit_balance - driverInfo.deposit_paid}
                               loading={addTransactionLoading}
                               driverId={driverInfo.id}
                               onAddTransaction={(values) => { addTransaction(values); }} />
                  <Divider type={"vertical"}/>
                  <BalanceInfo title={"Сводный баланс"}
                               code={"summary"}
                               value={driverInfo.fines_balance * -1}
                               loading={addTransactionLoading}
                               driverId={driverInfo.id}
                               onAddTransaction={(values) => {
                                 if(values.enablePayFines) {
                                   onAddPaymentTemplateExecutionPayments(values.finesToPay, values.comment);
                                 } else {
                                   addTransaction(values);
                                 }
                               }} />
                </Space>
              ) : null
          }
          <MenuButton value = "Сведения" statusBtn={selectedTab === "1"} onClick={() => setSelectedTab("1")}/>
          <MenuButton value = "Ведомость штрафов" statusBtn={selectedTab === "2"} onClick={() => setSelectedTab("2")}/>
          <MenuButton value = "Журнал транзакций" statusBtn={selectedTab === "3"} onClick={() => setSelectedTab("3")}/>
          <MenuButton value = "Журнал списаний" statusBtn={selectedTab === "paymentsHistory"} onClick={() => setSelectedTab("paymentsHistory")}/>
          <MenuButton value = "Условия работы" statusBtn={selectedTab === "4"} onClick={() => setSelectedTab("4")}/>
          <MenuButton value = "Учет времени" statusBtn={selectedTab === "supplyHours"} onClick={() => setSelectedTab("supplyHours")}/>
          <MenuButton value = "Договоры аренды" statusBtn={selectedTab === "5"} onClick={() => setSelectedTab("5")}/>
          <MenuButton value = "Файлы" statusBtn={selectedTab === "6"} onClick={() => setSelectedTab("6")}/>
          <MenuButton value = "Журнал" statusBtn={selectedTab === "7"} onClick={() => setSelectedTab("7")}/>
          {selectedTab === "1" && (
            <DriverPropertiesTab
                active={selectedTab === "1"}
                driver={driverInfo}
                onUpdated={() => {
                  setDriverInfoUpdate(prevState => prevState + 1);
                }}
                onDeleted={() => {
                  message.info("Водитель удален");
                  onClose(true);
                }}
            />
          )}
          {selectedTab === "2" && (
            <PaymentsTab loading={dataSourceLoading} payments={paymentsDataSource}/>
          )}
          {selectedTab === "3" && (
            <TransactionsTab driver={item} needUpdate={transactionsDataSourceStamp} />
          )}
          {selectedTab === "paymentsHistory" && (
            <Tabs type="card">
              <TabPane tab="Шаблоны списаний" key="1">
                <PaymentsExecutionHistoryTab
                  driver={item}
                  needUpdate={transactionsDataSourceStamp}
                  onUpdated={() => {
                    setDriverInfoUpdate(prevState => prevState + 1);
                  }}/>
              </TabPane>
              <TabPane tab="Ручные платежи" key="2">
                <PaymentsManualTab
                  driver={item}
                  needUpdate={transactionsDataSourceStamp}
                  onUpdated={() => {
                    setDriverInfoUpdate(prevState => prevState + 1);
                  }}/>
              </TabPane>
            </Tabs>
          )}
          {selectedTab === "4" && (
            <AssignedTransactionTemplatesTab
              updateStamp={assignedTransactionTemplatesStamp}
              driver={item}
              onUpdate={() => {
                setDriverInfoUpdate(prevState => prevState + 1)
              }}
            />
          )}
          {selectedTab === "5" && (
            <DriverContractsTab active={selectedTab === "5"} driver={item}/>
          )}
          {selectedTab === "6" && (
            <DriverFiles driver={item} active={selectedTab === "6"}/>
          )}
          {selectedTab === "7" && (
            <AuditTab driver={item}/>
          )}
          {selectedTab === "supplyHours" && (
            <SupplyHoursTab driver={item} active={selectedTab === "supplyHours"}/>
          )}
        </Modal>
      </>
    );
  }

  return (
    driverInfo ? draw() : null
  );
}